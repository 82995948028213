import React from "react"
import { Link } from "gatsby"

import { menu } from "./main-menu.module.scss"

const MainMenu = () => (
  <div className={menu}>
    <Link to="/">Hem</Link>
    <Link to="/secondHand">Lämna/Hämta</Link>
    <Link to="/openingHours">Öppettider</Link>
    <Link to="/events">Event</Link>
  </div>
)

export default MainMenu
