import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

import { footer, socialIcon } from "./footer.module.scss"

const Footer = () => (
  <footer className={footer}>
    <div>
      <a
        className={socialIcon}
        href="https://www.instagram.com/awesomerags_rawandrefined/"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className={socialIcon}
        href="https://www.facebook.com/awesomeragsrawandrefined/"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
    </div>
    <span>© {new Date().getFullYear()}, Awesome Rags</span>
  </footer>
)

export default Footer
